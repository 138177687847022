import { useMemo } from "react";

const useClients = () => {
  return useMemo(() => {
    const importAll = (r) => r.keys().map(r);
    return importAll(
      require.context("../../../assets/clientes", false, /\.(png|jpe?g|svg)$/)
    );
  }, []);
};

export default useClients;
