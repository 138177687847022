import { Button } from "antd";
import "../ui/components/DefaultButton.scss";

export default function DefaultButton({
  green,
  label,
  onClick,
  socialMedia,
  footerButton,
}) {
  const defaultClass = green
    ? "green-button"
    : socialMedia
    ? "social-media-button"
    : footerButton
    ? "footer-button"
    : "default-button";
  return (
    <>
      <Button className={defaultClass} onClick={onClick}>
        {label}
      </Button>
    </>
  );
}
