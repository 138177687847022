import { useMemo } from "react";
import banner_2 from "../../../assets/banners/banner_2.svg";
import banner_3 from "../../../assets/banners/banner_3.svg";
import calhas from "../../../assets/calhas/calhas.jpeg";
import corteEDobra from "../../../assets/corte-e-dobra/corte-e-dobra.png";
import perfiladeiras from "../../../assets/perfiladeiras/perfiladeiras.png";
import usinagem from "../../../assets/usinagem/usinagem.png";
import laser from "../../../assets/laser/laser.png";
import outros from "../../../assets/outros/outros.png";
import { Image } from "antd";
import { ROUTES } from "../../../routes";

const useCarouselItems = (handleRedirect) => {
  const carouselItems1 = useMemo(
    () => [
      <div
        key="banner_2"
        style={{ cursor: "pointer" }}
        onClick={() =>
          handleRedirect("page", ROUTES.PERFILADEIRAS_PERFILADEIRA_DUPLA, {
            machineType: "perfiladeira-dupla",
          })
        }
      >
        <Image src={banner_2} preview={false} />
      </div>,
      <div
        key="banner_3"
        style={{ cursor: "pointer" }}
        onClick={() =>
          handleRedirect("page", ROUTES.PERFILADEIRAS_PERFILADEIRA_TRIPLA, {
            machineType: "perfiladeira-tripla",
          })
        }
      >
        <Image src={banner_3} preview={false} />
      </div>,
    ],
    [handleRedirect]
  );

  const carouselItems2 = useMemo(
    () => [
      {
        type: "calhas",
        image: calhas,
        route: ROUTES.CALHAS,
      },
      {
        type: "perfiladeiras",
        image: perfiladeiras,
        route: ROUTES.PERFILADEIRAS,
      },
      {
        type: "corte-e-dobra",
        image: corteEDobra,
        route: ROUTES.CORTE_E_DOBRA,
      },
      {
        type: "usinagem",
        image: usinagem,
        route: ROUTES.USINAGEM,
      },
      {
        type: "laser",
        image: laser,
        route: ROUTES.LASER,
      },
      {
        type: "outros",
        image: outros,
        route: ROUTES.OUTROS,
      },
    ],
    []
  );

  return { carouselItems1, carouselItems2 };
};

export default useCarouselItems;
