import React, { useEffect } from "react";
import { Col, Image, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutDesktop from "./LayoutDesktop";
import MachineCarousel from "../../components/MachineCarousel";
import LinkedFooterImage from "../../components/LinkedFooterImage";
import useMachineCarousels from "../desktop/hooks/useMachinesCarousel";
import useHandleRedirect from "../../hooks/useHandleRedirect";

export default function MachinesScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split("/");
  const machineType = pathSegments[pathSegments.length - 1];

  const handleRedirect = useHandleRedirect();

  useEffect(() => {
    if (!machineType) {
      navigate("/");
    }
  }, [machineType, navigate]);

  const {
    carouselCalhas,
    carouselDobradeirasManuais,
    carouselDobradeirasHidraulicas,
    carouselAcessorios,
    carouselCorteEDobra,
    carouselDobradeirasHidraulicasCNECNC,
    carouselGuilhotinasCNECNC,
    carouselPerfiladeiras,
    carouselUsinagem,
    carouselTornos,
    carouselFresas,
    carouselLaser,
    carouselCorteALaser,
    carouselOutros,
  } = useMachineCarousels();

  if (machineType === "calhas") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselCalhas.map((machine, index) => (
              <Col span={4} key={index}>
                <Link onClick={() => handleRedirect("page", `${machine.type}`)}>
                  <MachineCarousel image={machine.image} />
                </Link>
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "dobradeiras-manuais") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselDobradeirasManuais.map((machine, index) => (
              <Col span={4} key={index}>
                <MachineCarousel image={machine.image} />
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "dobradeiras-hidraulicas") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselDobradeirasHidraulicas.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type === "dobradeiras-hidraulicas-lisa" ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "acessorios") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselAcessorios.map((machine, index) => (
              <Col span={4} key={index}>
                <MachineCarousel image={machine.image} />
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "corte-e-dobra") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselCorteEDobra.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type.includes("dobradeiras") ||
                  machine.type.includes("guilhotinas") ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "dobradeiras") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselDobradeirasHidraulicasCNECNC.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type === "dobradeiras-hidraulicas-cn" ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "guilhotinas") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselGuilhotinasCNECNC.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type === "guilhotinas-cn" ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "perfiladeiras") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselPerfiladeiras.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type.includes("perfiladeira") ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "usinagem") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselUsinagem.map((machine, index) => (
              <Col span={4} key={index}>
                <Link onClick={() => handleRedirect("page", `${machine.type}`)}>
                  <MachineCarousel image={machine.image} />
                </Link>
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "tornos") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselTornos.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type === "torno-convencional" ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "fresas") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselFresas.map((machine, index) => (
              <Col span={4} key={index}>
                <MachineCarousel image={machine.image} />
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "laser") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselLaser.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type.includes("corte-a-laser") ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "corte-a-laser") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselCorteALaser.map((machine, index) => (
              <Col span={4} key={index}>
                {machine.type.includes("maquina-corte-a-laser") ? (
                  <Link
                    onClick={() => handleRedirect("page", `${machine.type}`)}
                  >
                    <MachineCarousel image={machine.image} />
                  </Link>
                ) : (
                  <MachineCarousel image={machine.image} />
                )}
              </Col>
            ))}
          </Row>
        }
      />
    );
  } else if (machineType === "outros") {
    return (
      <LayoutDesktop
        content={
          <Row
            className="ant-row-content-machine-carousel"
            justify="space-evenly"
            align="middle"
          >
            {carouselOutros.map((machine, index) => (
              <Col span={4} key={index}>
                <MachineCarousel image={machine.image} />
              </Col>
            ))}
          </Row>
        }
      />
    );
  }

  function importAll(r) {
    return r.keys().map(r);
  }

  let imgs = [];
  switch (machineType) {
    case "perfiladeira-simples":
      imgs = importAll(
        require.context(
          "../../assets/perfiladeiras/perfiladeira-simples",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "perfiladeira-dupla":
      imgs = importAll(
        require.context(
          "../../assets/perfiladeiras/perfiladeira-dupla",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "perfiladeira-tripla":
      imgs = importAll(
        require.context(
          "../../assets/perfiladeiras/perfiladeira-tripla",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "dobradeiras-hidraulicas-lisa":
      imgs = importAll(
        require.context(
          "../../assets/calhas/dobradeiras-hidraulicas-lisa",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "dobradeiras-hidraulicas-cn":
      imgs = importAll(
        require.context(
          "../../assets/corte-e-dobra/dobradeiras-hidraulicas-cn",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "guilhotinas-cn":
      imgs = importAll(
        require.context(
          "../../assets/corte-e-dobra/guilhotinas-cn",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "torno-convencional":
      imgs = importAll(
        require.context(
          "../../assets/usinagem/torno-convencional",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "maquina-corte-a-laser":
      imgs = importAll(
        require.context(
          "../../assets/laser/maquina-corte-a-laser",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    default:
      navigate("/");
      return null;
  }

  const content = imgs.map((img, index) => {
    const isLast = index === imgs.length - 1;
    return (
      <Row
        key={index}
        justify="center"
        align="middle"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <Col style={{ position: "relative", width: "95vw" }}>
          {isLast ? (
            <LinkedFooterImage
              imgSrc={img}
              link="https://api.whatsapp.com/send/?phone=%2B554195440770&text=type=phone_number&app_absent=0"
              machineType={machineType}
            />
          ) : (
            <Image src={img} preview={false} style={{ width: "95vw" }} />
          )}
        </Col>
      </Row>
    );
  });

  return (
    <>
      <LayoutDesktop content={content} />
    </>
  );
}
