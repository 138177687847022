import React, { useState, useEffect } from "react";
import thumbnail_video from "../assets/video/thumbnail_video.svg";

export default function YouTubeVideo({ videoId }) {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < window.innerHeight
  );
  const [playing, setPlaying] = useState(false);
  const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`;
  const handlePlayVideo = () => {
    setPlaying(true);
  };
  const videoStyleMobile = { width: "100%", height: "100%", cursor: "pointer" };
  const videoStyleDesktop = {
    width: "700px",
    height: "600px",
    cursor: "pointer",
  };
  const combinedStyle = isMobile
    ? { ...videoStyleMobile }
    : { ...videoStyleDesktop };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="thumbnail-video">
      {!playing && (
        <img
          src={thumbnail_video}
          alt="Play video"
          style={combinedStyle}
          onClick={handlePlayVideo}
        />
      )}
      {playing && (
        <iframe
          style={combinedStyle}
          src={videoSrc}
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="Embedded YouTube Video"
        ></iframe>
      )}
    </div>
  );
}
