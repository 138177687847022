import React, { useRef, useState } from "react";
import {
  Layout,
  Button,
  Drawer,
  Image,
  Dropdown,
  Row,
  Col,
  Typography,
  Input,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  WhatsAppOutlined,
  InstagramOutlined,
  FacebookFilled,
  LinkedinFilled,
  YoutubeOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import ButtonWhiteMenu from "../../components/ButtonWhiteMenu";
import HeaderMenu from "../../components/HeaderMenu";
import useHandleRedirect from "../../hooks/useHandleRedirect";
import SocialMediaButtons from "../../components/SocialMediaButtons";
import fachini_logo from "../../assets/logos/fachini_maquinas.png";
import logo_fachini_4 from "../../assets/logos/logo_fachini_4.png";
import logo_fachini_7 from "../../assets/logos/logo_fachini_7.png";

const { Header, Footer, Content } = Layout;

const layoutStyle = {
  width: "100%",
  maxWidth: "100%",
  scrollbarWidth: 0,
  backgroundColor: "white",
};

const buttonSocialMediaStyleFooter = {
  border: "none",
  backgroundColor: "transparent",
  color: "white",
};

const titleFooterStyle = {
  color: "white",
  fontSize: "24px",
  fontWeight: "700",
};

const titleSmallFooterStyle = {
  color: "white",
  fontSize: "14px",
  fontWeight: "700",
};

const linkFooterStyle = {
  color: "white",
  fontSize: "16px",
  fontWeight: "400",
};

function LayoutMobile(props) {
  const [open, setOpen] = useState(false);
  const footerRef = useRef(null);
  const handleRedirect = useHandleRedirect();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
    onClose();
  };

  return (
    <Layout style={layoutStyle}>
      <Drawer
        width={"60%"}
        style={{
          margin: 0,
          backgroundColor: "#15274E",
          color: "white",
          alignItems: "center",
          overflow: "hidden",
        }}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
        title={<Image preview={false} src={logo_fachini_4} />}
        onClose={onClose}
        open={open}
        placement="left"
      >
        <ButtonWhiteMenu
          onClick={() => handleRedirect("page", "/")}
          text="HOME"
        />
        <ButtonWhiteMenu
          onClick={() => handleRedirect("page", "/quem-somos")}
          text="QUEM SOMOS"
        />
        <Dropdown overlay={<HeaderMenu handleRedirect={handleRedirect} />}>
          <ButtonWhiteMenu text="CATÁLOGO">
            CATÁLOGO <DownOutlined />
          </ButtonWhiteMenu>
        </Dropdown>
        <ButtonWhiteMenu onClick={scrollToFooter} text="CONTATO" />
      </Drawer>
      <Header
        style={{
          textAlign: "center",
          justifyContent: "center",
          color: "#fff",
          height: 200,
          backgroundColor: "#FFFFFF",
          padding: 0,
        }}
      >
        <Button
          style={{
            width: "90%",
            height: "32px",
            margin: "10px 0 10px 0",
            backgroundColor: "#02AD3C",
            borderColor: "#02AD3C",
            color: "white",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "22px",
            textAlign: "center",
          }}
          onClick={() =>
            handleRedirect(
              "link",
              "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
            )
          }
        >
          Solicite Orçamento
        </Button>
        <Row justify="space-around" align="middle">
          <Col span={4}>
            <Button
              style={{
                backgroundColor: "white",
                color: "#E01E26",
                borderColor: "#15274E",
              }}
              icon={<MenuOutlined />}
              type="primary"
              onClick={showDrawer}
            />
          </Col>
          <Col span={8}>
            <Link onClick={() => handleRedirect("page", "/")}>
              <Image src={fachini_logo} preview={false} />
            </Link>
          </Col>
          <Col
            style={{
              justifyContent: "right",
              display: "flex",
              gap: "2px",
              marginTop: "15px",
              marginLeft: "15px",
            }}
          >
            <SocialMediaButtons handleRedirect={handleRedirect} />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", paddingInline: "25px" }}>
          <Input placeholder="Procure produto" suffix={<SearchOutlined />} />
        </Row>
      </Header>
      <Content>{props.children}</Content>
      <Footer style={{ backgroundColor: "#15274E", color: "white" }}>
        <Image
          src={logo_fachini_7}
          preview={false}
          style={{ height: "50%", width: "50%" }}
          align="center"
        />
        <Row
          style={{
            justifyContent: "center",
            gap: 20,
            width: "100%",
            height: "10vh",
            marginTop: 20,
          }}
        >
          <Col>
            <Button
              style={buttonSocialMediaStyleFooter}
              icon={<LinkedinFilled style={{ fontSize: "32px" }} />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.linkedin.com/company/fachini-distribuidora/"
                )
              }
            />
          </Col>
          <Col>
            <Button
              style={buttonSocialMediaStyleFooter}
              icon={<InstagramOutlined style={{ fontSize: "32px" }} />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.instagram.com/fachinimaquinas"
                )
              }
            />
          </Col>
          <Col>
            <Button
              style={buttonSocialMediaStyleFooter}
              icon={<FacebookFilled style={{ fontSize: "32px" }} />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.facebook.com/fachiniautomotiva"
                )
              }
            />
          </Col>
          <Col>
            <Button
              style={buttonSocialMediaStyleFooter}
              icon={<YoutubeOutlined style={{ fontSize: "32px" }} />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.youtube.com/@dobradeirasfachini2481"
                )
              }
            />
          </Col>
        </Row>
        <Row justify="space-evenly">
          <Col ref={footerRef} span={12}>
            <Typography style={titleFooterStyle}>Visão Geral</Typography>
            <br />
            <Link
              style={linkFooterStyle}
              onClick={() => handleRedirect("page", "/quem-somos")}
            >
              Quem Somos
            </Link>
            <br />
            <Dropdown overlay={<HeaderMenu handleRedirect={handleRedirect} />}>
              <Link style={linkFooterStyle}>Catálogo</Link>
            </Dropdown>
            <br />
            <Link
              style={linkFooterStyle}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
                )
              }
            >
              Assistência
            </Link>
          </Col>
          <br />
          <br />
          <Col span={12}>
            <Typography style={titleFooterStyle}>Unidades</Typography>
            <br />
            <Col>
              <Typography style={titleSmallFooterStyle}>Curitiba/PR</Typography>
              <Typography style={linkFooterStyle}>
                Estr. do Ganchinho, 957
              </Typography>
              <Typography style={linkFooterStyle}>(41) 3099-3551</Typography>
              <br />
              <Typography style={titleSmallFooterStyle}>Cascavel/PR</Typography>
              <Typography style={linkFooterStyle}>
                Guerino de Carli, 6807 <br />
                Marginal, 369. Km 170
              </Typography>
              <Typography style={linkFooterStyle}>(45) 3219-9900</Typography>
              <br />
            </Col>
            <Col>
              <Typography style={titleSmallFooterStyle}>
                Joinville/SC
              </Typography>
              <Typography style={linkFooterStyle}>
                Princesa Mafalda, 477
              </Typography>
              <Typography style={linkFooterStyle}>(47) 3305-9243</Typography>
              <br />
              <Typography style={titleSmallFooterStyle}>Chapecó/SC</Typography>
              <Typography style={linkFooterStyle}>
                Leopoldo Sander, 91
              </Typography>
              <Typography style={linkFooterStyle}>(49) 3319-6900</Typography>
              <br />
            </Col>
          </Col>
        </Row>
      </Footer>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        }}
        onClick={() =>
          handleRedirect(
            "link",
            "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
          )
        }
      >
        <WhatsAppOutlined
          style={{
            fontSize: "50px",
            color: "#25D366",
          }}
        />
      </div>
    </Layout>
  );
}

export default LayoutMobile;
