import React, { useState, useEffect } from "react";
import LayoutMobile from "./LayoutMobile";
import banner_2_mobile from "../../assets/banners/banner_2_mobile.svg";
import banner_3_mobile from "../../assets/banners/banner_3_mobile.svg";
import calhas from "../../assets/calhas/calhas.jpeg";
import corteEDobra from "../../assets/corte-e-dobra/corte-e-dobra.png";
import usinagem from "../../assets/usinagem/usinagem.png";
import perfiladeiras from "../../assets/perfiladeiras/perfiladeiras.png";
import laser from "../../assets/laser/laser.png";
import outros from "../../assets/outros/outros.png";
import CustomCarousel from "../../components/CustomCarousel";
import { Carousel, Col, Image, Row } from "antd";
import CarouselItem from "../../components/CarouselItem";
import YouTubeVideo from "../../components/YoutubeVideo";
import useHandleRedirect from "../../hooks/useHandleRedirect";

function MobileScreen() {
  const clients = require
    .context("../../assets/clientes", false, /\.(png|jpe?g|svg)$/)
    .keys()
    .map(require.context("../../assets/clientes", false, /\.(png|jpe?g|svg)$/));
  const handleRedirect = useHandleRedirect();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getSlidesToShow = () => {
    if (windowWidth <= 425) {
      return 2;
    } else if (windowWidth > 425 && windowWidth <= 768) {
      return 4;
    }
  };

  const carouselItems1 = [
    {
      key: "banner_2",
      imageSrc: banner_2_mobile,
      title: "Máquinas",
      subtitle: "PerfiladeiraDupla",
      url: "/perfiladeiras/perfiladeira-dupla",
      type: "perfiladeira-dupla",
    },
    {
      key: "banner_3",
      imageSrc: banner_3_mobile,
      title: "Máquinas",
      subtitle: "PerfiladeiraTripla",
      url: "/perfiladeiras/perfiladeira-tripla",
      type: "perfiladeira-tripla",
    },
  ].map((item) => (
    <div
      key={item.key}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 0 30px 0",
        borderRadius: "20px 20px 0 0",
      }}
    >
      <CarouselItem
        imageSrc={item.imageSrc}
        title={item.title}
        subtitle={item.subtitle}
        onClick={() =>
          handleRedirect("page", item.url, { machineType: item.type })
        }
        first
      />
    </div>
  ));

  const carouselItems2 = [
    {
      key: "calhas",
      imageSrc: calhas,
      url: "/calhas",
      type: "calhas",
    },
    {
      key: "perfiladeiras",
      imageSrc: perfiladeiras,
      url: "/perfiladeiras",
      type: "perfiladeiras",
    },
    {
      key: "corte-e-dobra",
      imageSrc: corteEDobra,
      url: "/corte-e-dobra",
      type: "corte-e-dobra",
    },
    {
      key: "usinagem",
      imageSrc: usinagem,
      url: "/usinagem",
      type: "usinagem",
    },
    {
      key: "laser",
      imageSrc: laser,
      url: "/laser",
      type: "laser",
    },
    {
      key: "outros",
      imageSrc: outros,
      url: "/outros",
      type: "outros",
    },
  ].map((item) => (
    <div
      key={item.key}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 0 30px 0",
        borderRadius: "20px 20px 0 0",
      }}
    >
      <CarouselItem
        imageSrc={item.imageSrc}
        title={item.title}
        subtitle={item.subtitle}
        onClick={() =>
          handleRedirect("page", item.url, { machineType: item.type })
        }
      />
    </div>
  ));

  return (
    <div style={{ scrollTop: 0 }}>
      <LayoutMobile>
        <CustomCarousel items={carouselItems1} />
        <CustomCarousel items={carouselItems2} />
        <YouTubeVideo videoId="6KZZMaYdbe8" />
        <Row
          className="ant-row-title-our-clients"
          justify="center"
          align="middle"
        >
          <Col
            className="ant-col-title-our-clients"
            style={{ fontSize: "30px", fontWeight: 700, padding: "20px" }}
          >
            NOSSOS CLIENTES
          </Col>
        </Row>
        <Carousel
          autoplay
          slidesToShow={getSlidesToShow()}
          slidesToScroll={getSlidesToShow()}
          infinite
          draggable
          style={{ backgroundColor: "#FFFFFF" }}
        >
          {clients.map((client, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={client}
                preview={false}
                style={{ width: "200px", height: "200px" }}
              />
            </div>
          ))}
        </Carousel>
      </LayoutMobile>
    </div>
  );
}

export default MobileScreen;
