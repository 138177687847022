import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ROUTES } from "./routes";
import App from "./App";
import QuemSomos from "./components/QuemSomos";
import MachinesScreen from "./pages/desktop/MachinesScreen";
import MachinesScreenMobile from "./pages/mobile/MachinesScreenMobile";
import useIsMobile from "./hooks/useIsMobile";

const AppContainer = () => {
  const isMobile = useIsMobile();

  const router = createBrowserRouter([
    { path: ROUTES.HOME, element: <App /> },
    { path: ROUTES.QUEM_SOMOS, element: <QuemSomos /> },
    {
      path: ROUTES.PERFILADEIRAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.PERFILADEIRAS_PERFILADEIRA_SIMPLES,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.PERFILADEIRAS_PERFILADEIRA_DUPLA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.PERFILADEIRAS_PERFILADEIRA_TRIPLA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_DOBRADEIRAS_MANUAIS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_DOBRADEIRAS_MANUAIS_LISA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_DOBRADEIRAS_MANUAIS_DENTADA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_DOBRADEIRAS_HIDRAULICAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_DOBRADEIRAS_HIDRAULICAS_LISA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_DOBRADEIRAS_HIDRAULICAS_DENTADA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_ACESSORIOS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_ACESSORIOS_CALANDRA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_ACESSORIOS_FRESADORA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_ACESSORIOS_TESOURA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_ACESSORIOS_DOBRADOR_SUPORTE,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CALHAS_ACESSORIOS_FECHADOR_CONDUTOR,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA_DOBRADEIRAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA_DOBRADEIRAS_HIDRAULICAS_CN,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA_GUILHOTINAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA_GUILHOTINAS_GUILHOTINAS_CN,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA_PRENSA_HIDRAULICA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.CORTE_E_DOBRA_METALEIRA,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.USINAGEM,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.USINAGEM_TORNOS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.USINAGEM_TORNOS_TORNO_CONVENCIONAL,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.USINAGEM_FRESAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER_CORTE_A_LASER,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER_CORTE_A_LASER_MAQUINA_CORTE_A_LASER,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER_CORTE_A_LASER_CHAPAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER_CORTE_A_LASER_COMBINADO_TUBOS_E_CHAPAS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER_CORTE_A_LASER_TUBOS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.LASER_SOLDA_A_LASER,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
    {
      path: ROUTES.OUTROS,
      element: isMobile ? <MachinesScreenMobile /> : <MachinesScreen />,
    },
  ]);

  React.useEffect(() => {
    const unlisten = router.subscribe(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [router]);

  return <RouterProvider router={router} />;
};

export default AppContainer;
