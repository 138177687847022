import React, { useRef } from "react";
import {
  Col,
  Divider,
  Image,
  Layout,
  Row,
  Typography,
  Dropdown,
  Button,
} from "antd";
import { Link } from "react-router-dom";
import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  SearchOutlined,
  WhatsAppOutlined,
  YoutubeFilled,
} from "@ant-design/icons";

import DefaultButton from "../../components/DefaultButton.js";
import DefaultInput from "../../components/DefaultInput.js";
import HeaderMenu from "../../components/HeaderMenu.js";

import fachini_logo from "../../assets/logos/fachini_logo_transparente.png";
import logo_fachini_7 from "../../assets/logos/logo_fachini_7.png";

import useHandleRedirect from "../../hooks/useHandleRedirect.js";

import "../../ui/components/LayoutDesktop.scss";

const HeaderSection = ({ handleRedirect, headerRef, scrollToRef }) => (
  <>
    <Row className="ant-row-header" ref={headerRef}>
      <Col span={12} className="align-fachini-logo">
        <Link onClick={() => handleRedirect("page", "/")}>
          <Image src={fachini_logo} preview={false} />
        </Link>
      </Col>
      <Col span={12} className="align-btns">
        <DefaultButton
          label={"Solicite Orçamento"}
          onClick={() =>
            handleRedirect(
              "link",
              "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
            )
          }
          green
        />
        <Row>
          <Col>
            <DefaultButton
              label={<FacebookFilled />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.facebook.com/fachiniautomotiva"
                )
              }
              socialMedia
            />
          </Col>
          <Col>
            <DefaultButton
              label={<YoutubeFilled />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.youtube.com/@dobradeirasfachini2481"
                )
              }
              socialMedia
            />
          </Col>
          <Col>
            <DefaultButton
              label={<WhatsAppOutlined />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
                )
              }
              socialMedia
            />
          </Col>
          <Col>
            <DefaultButton
              label={<InstagramOutlined />}
              onClick={() =>
                handleRedirect(
                  "link",
                  "https://www.instagram.com/fachinimaquinas"
                )
              }
              socialMedia
            />
          </Col>
        </Row>
        <Divider />
        <DefaultButton
          label={"HOME"}
          onClick={() => handleRedirect("page", "/")}
        />
        <DefaultButton
          label={"QUEM SOMOS"}
          onClick={() => handleRedirect("page", "/quem-somos")}
        />
        <Dropdown overlay={<HeaderMenu handleRedirect={handleRedirect} />}>
          <Button>CATÁLOGO</Button>
        </Dropdown>
        <DefaultButton
          label={"CONTATO"}
          onClick={() => scrollToRef({ footer: true })}
        />
        <DefaultInput
          placeholder="Procure produto"
          suffix={<SearchOutlined />}
          style={{ width: "20%" }}
        />
      </Col>
    </Row>
    <div className="menu"></div>
  </>
);

const FooterSection = ({ handleRedirect, footerRef, scrollToRef }) => (
  <Row
    className="ant-row-footer"
    justify="space-evenly"
    align="top"
    ref={footerRef}
  >
    <Col className="ant-col-footer" span={4} style={{ marginLeft: "15%" }}>
      <Image
        className="ant-row-footer-logo"
        src={logo_fachini_7}
        preview={false}
      />
      <Row
        className="ant-row-footer-logo"
        justify="space-between"
        align="middle"
        style={{ margin: "25px 0 25px 0 " }}
      >
        <Col>
          <DefaultButton
            label={<LinkedinFilled style={{ fontSize: "25px" }} />}
            onClick={() =>
              handleRedirect(
                "link",
                "https://www.linkedin.com/company/fachini-distribuidora/"
              )
            }
            footerButton
          />
        </Col>
        <Col>
          <DefaultButton
            label={<InstagramOutlined style={{ fontSize: "25px" }} />}
            onClick={() =>
              handleRedirect(
                "link",
                "https://www.instagram.com/fachinimaquinas"
              )
            }
            footerButton
          />
        </Col>
        <Col>
          <DefaultButton
            label={<FacebookFilled style={{ fontSize: "25px" }} />}
            onClick={() =>
              handleRedirect(
                "link",
                "https://www.facebook.com/fachiniautomotiva"
              )
            }
            footerButton
          />
        </Col>
        <Col>
          <DefaultButton
            label={<YoutubeFilled style={{ fontSize: "25px" }} />}
            onClick={() =>
              handleRedirect(
                "link",
                "https://www.youtube.com/@dobradeirasfachini2481"
              )
            }
            footerButton
          />
        </Col>
      </Row>
    </Col>
    <Col className="ant-col-footer" span={4}>
      <Typography className="ant-row-footer-title">Visão Geral</Typography>
      <br />
      <Link
        className="ant-row-footer-body"
        onClick={() => scrollToRef({ header: true })}
      >
        Quem Somos
      </Link>
      <br />
      <Dropdown overlay={<HeaderMenu handleRedirect={handleRedirect} />}>
        <Link className="ant-row-footer-body">Catálogo</Link>
      </Dropdown>
      <br />
      <Link
        className="ant-row-footer-body"
        onClick={() =>
          handleRedirect(
            "link",
            "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
          )
        }
      >
        Assistência
      </Link>
      {/* <br />
      <Link className="ant-row-footer-body">Contato</Link>
      <br />
      <Typography className="ant-row-footer-title-2">Versão 2.0</Typography> */}
    </Col>
    <Col className="ant-col-footer" span={10}>
      <Typography className="ant-row-footer-title">Unidades</Typography>
      <br />
      <Row>
        <Col span={10}>
          <Typography className="ant-row-footer-title-2">
            Curitiba/PR
          </Typography>
          <Typography className="ant-row-footer-body-2">
            Estr. do Ganchinho, 957
          </Typography>
          <Typography className="ant-row-footer-body-2">
            (41) 3099-3551
          </Typography>
          <br />
          <Typography className="ant-row-footer-title-2">
            Cascavel/PR
          </Typography>
          <Typography className="ant-row-footer-body-2">
            Guerino de Carli, 6807 <br />
            Marginal, 369. Km 170
          </Typography>
          <Typography className="ant-row-footer-body-2">
            (45) 3219-9900
          </Typography>
          <br />
        </Col>
        <Col span={10}>
          <Typography className="ant-row-footer-title-2">
            Joinville/SC
          </Typography>
          <Typography className="ant-row-footer-body-2">
            Princesa Mafalda, 477
          </Typography>
          <Typography className="ant-row-footer-body-2">
            (47) 3305-9243
          </Typography>
          <br />
          <Typography className="ant-row-footer-title-2">Chapecó/SC</Typography>
          <Typography className="ant-row-footer-body-2">
            Leopoldo Sander, 91
          </Typography>
          <Typography className="ant-row-footer-body-2">
            (49) 3319-6900
          </Typography>
          <br />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default function LayoutDesktop({ content }) {
  const { Header, Footer, Content } = Layout;
  const footerRef = useRef(null);
  const headerRef = useRef(null);

  const scrollToRef = ({ header }) => {
    if (header === true) {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
      handleRedirect("page", "/quem-somos");
    } else {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRedirect = useHandleRedirect();

  return (
    <Layout className="ant-layout-desktop">
      <Header>
        <HeaderSection
          handleRedirect={handleRedirect}
          headerRef={headerRef}
          scrollToRef={scrollToRef}
        />
      </Header>
      <Content>{content}</Content>
      <Footer>
        <FooterSection
          handleRedirect={handleRedirect}
          footerRef={footerRef}
          scrollToRef={scrollToRef}
        />
      </Footer>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        }}
        onClick={() =>
          handleRedirect(
            "link",
            "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
          )
        }
      >
        <WhatsAppOutlined
          style={{
            fontSize: "50px",
            color: "#25D366",
          }}
        />
      </div>
    </Layout>
  );
}
