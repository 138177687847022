import { useNavigate } from "react-router-dom";

const useHandleRedirect = () => {
  const navigate = useNavigate();

  const handleRedirect = (type, url, state = {}) => {
    if (type === "link") {
      window.open(url, "_blank");
    } else if (type === "page") {
      navigate(url, { state });
    }
  };

  return handleRedirect;
};

export default useHandleRedirect;
