import React, { useRef } from 'react';
import { Carousel } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

// Custom arrow components
const NextArrowMobile = ({ onClick }) => (
    <div onClick={onClick} style={{ ...arrowStylesMobile, right: 10 }}>
        <ArrowRightOutlined />
    </div>
);

const PrevArrowMobile = ({ onClick }) => (
    <div onClick={onClick} style={{ ...arrowStylesMobile, left: 10 }}>
        <ArrowLeftOutlined />
    </div>
);

const NextArrowDesktop = ({ onClick }) => (
    <div onClick={onClick} style={{ ...arrowStylesDesktop, right: 10 }}>
        <RightOutlined />
    </div>
);

const PrevArrowDesktop = ({ onClick }) => (
    <div onClick={onClick} style={{ ...arrowStylesDesktop, left: 10 }}>
        <LeftOutlined />
    </div>
);

// Styles for the arrows (customize as needed)
const arrowStylesMobile = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '24px', // Increase size for better mobile accessibility
    color: '#878787',
    cursor: 'pointer',
    zIndex: 2,
};

const arrowStylesDesktop = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '54px',
    height: '54px',
    top: '50%',
    borderRadius: '50%',
    transform: 'translateY(-50%)',
    fontSize: '24px',
    cursor: 'pointer',
    backgroundColor: '#15274E',
    color: '#FFFFFF',
    zIndex: 2,
};

const CustomCarousel = ({ arrows, items, desktop }) => {
    const carouselRef = useRef();

    const gotoNext = () => carouselRef.current.next();
    const gotoPrev = () => carouselRef.current.prev();

    return (
        <>
            <Carousel
                ref={carouselRef}
                arrows={arrows ? true : false}
                nextArrow={desktop ? <NextArrowDesktop onClick={gotoNext} /> : <NextArrowMobile onClick={gotoNext} />}
                prevArrow={desktop ? <PrevArrowDesktop onClick={gotoPrev} /> : <PrevArrowMobile onClick={gotoPrev} />}
                style={{ maxWidth: '100vw', marginBottom: 10 }}
                draggable
                dots={true}
            >
                {items.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </Carousel>
        </>
    );
};

export default CustomCarousel;