import { Input } from "antd";
import "../ui/components/DefaultInput.scss";

export default function DefaultInput({
  className,
  placeholder,
  suffix,
  style,
}) {
  const defaultStyle = {};
  return (
    <>
      <Input
        className={className}
        style={style ?? defaultStyle}
        placeholder={placeholder}
        suffix={suffix}
      />
    </>
  );
}
