import React, { useEffect } from "react";
import { Col, Image, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutMobile from "./LayoutMobile";
import CustomCarousel from "../../components/CustomCarousel";
import CarouselItem from "../../components/CarouselItem";
import useHandleRedirect from "../../hooks/useHandleRedirect";
import images from "../images";
import LinkedFooterImage from "../../components/LinkedFooterImage";

export default function MachinesScreenMobile() {
  const location = useLocation();
  const navigate = useNavigate();
  const machineType = location.pathname.split("/").pop();
  const handleRedirect = useHandleRedirect();

  useEffect(() => {
    if (!machineType) navigate("/");
  }, [machineType, navigate]);

  const carouselItems = (items) =>
    items.map((item) => (
      <div
        key={item.key}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "20px",
          paddingBottom: "30px",
          borderRadius: "20px 20px 0 0",
        }}
      >
        <CarouselItem
          imageSrc={item.imageSrc}
          onClick={() =>
            handleRedirect("page", item.url, { machineType: item.type })
          }
          machineType={item.type}
        />
      </div>
    ));

  const carousels = {
    calhas: carouselItems(images.calhas),
    "dobradeiras-manuais": carouselItems(images.dobradeirasManuaisItems),
    "dobradeiras-hidraulicas": carouselItems(
      images.dobradeirasHidraulicasItems
    ),
    acessorios: carouselItems(images.acessoriosItems),
    "corte-e-dobra": carouselItems(images.corteEDobraItems),
    dobradeiras: carouselItems(images.dobradeirasCNECNCItems),
    guilhotinas: carouselItems(images.guilhotinasCNECNCItems),
    perfiladeiras: carouselItems(images.perfiladeirasItems),
    usinagem: carouselItems(images.usinagem),
    tornos: carouselItems(images.tornosItems),
    fresas: carouselItems(images.fresasItems),
    laser: carouselItems(images.laserItems),
    "corte-a-laser": carouselItems(images.corteALaserItems),
    outros: carouselItems(images.outros),
  };

  if (carousels[machineType]) {
    return (
      <LayoutMobile>
        <CustomCarousel items={carousels[machineType]} />
      </LayoutMobile>
    );
  }

  const importAll = (r) => r.keys().map(r);
  let imgs = [];
  switch (machineType) {
    case "perfiladeira-simples":
      imgs = importAll(
        require.context(
          "../../assets/perfiladeiras/perfiladeira-simples",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "perfiladeira-dupla":
      imgs = importAll(
        require.context(
          "../../assets/perfiladeiras/perfiladeira-dupla",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "perfiladeira-tripla":
      imgs = importAll(
        require.context(
          "../../assets/perfiladeiras/perfiladeira-tripla",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "dobradeiras-hidraulicas-lisa":
      imgs = importAll(
        require.context(
          "../../assets/calhas/dobradeiras-hidraulicas-lisa",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "dobradeiras-hidraulicas-cn":
      imgs = importAll(
        require.context(
          "../../assets/corte-e-dobra/dobradeiras-hidraulicas-cn",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "guilhotinas-cn":
      imgs = importAll(
        require.context(
          "../../assets/corte-e-dobra/guilhotinas-cn",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "torno-convencional":
      imgs = importAll(
        require.context(
          "../../assets/usinagem/torno-convencional",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    case "maquina-corte-a-laser":
      imgs = importAll(
        require.context(
          "../../assets/laser/maquina-corte-a-laser",
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
      break;
    default:
      imgs = [];
  }

  return (
    <LayoutMobile>
      {imgs.map((img, index) => (
        <Row
          key={index}
          justify="center"
          align="middle"
          style={{ backgroundColor: "#FFFFFF", position: "relative" }}
        >
          <Col>
            {index === imgs.length - 1 ? (
              <LinkedFooterImage
                imgSrc={img}
                link="https://api.whatsapp.com/send/?phone=%2B554195440770&text=type=phone_number&app_absent=0"
                machineType={machineType}
              />
            ) : (
              <Image src={img} preview={false} style={{ width: "95vw" }} />
            )}
          </Col>
        </Row>
      ))}
    </LayoutMobile>
  );
}
