import React, { useEffect } from "react";
import { Col, Image, Row, Carousel } from "antd";
import { Link } from "react-router-dom";
import CustomCarousel from "../../components/CustomCarousel";
import LayoutDesktop from "./LayoutDesktop";
import MachineCarousel from "../../components/MachineCarousel";
import YouTubeVideo from "../../components/YoutubeVideo";
import useClients from "./hooks/useClients";
import useCarouselItems from "./hooks/useCarouselItems";
import useHandleRedirect from "../../hooks/useHandleRedirect";

const DesktopScreen = () => {
  const handleRedirect = useHandleRedirect();

  const clients = useClients();
  const { carouselItems1, carouselItems2 } = useCarouselItems(handleRedirect);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customCarousel = (
    <CustomCarousel arrows desktop items={carouselItems1} />
  );

  const clientCarouselItems = clients.map((client, index) => (
    <Image key={index} src={client} preview={false} />
  ));

  return (
    <LayoutDesktop
      content={
        <>
          {customCarousel}
          <Carousel autoplay={true} slidesToShow={3} dots={true}>
            {carouselItems2.map((machine, index) => (
              <div key={index}>
                <Link onClick={() => handleRedirect("page", machine.route)}>
                  <MachineCarousel image={machine.image} carousel={true} />
                </Link>
              </div>
            ))}
          </Carousel>
          <Row
            className="ant-row-content-youtube"
            justify="center"
            align="middle"
          >
            <Col style={{ padding: 20 }}>
              <YouTubeVideo videoId="6KZZMaYdbe8" />
            </Col>
          </Row>
          <Row className="ant-row-title-our-clients">
            <Col className="ant-col-title-our-clients">NOSSOS CLIENTES</Col>
          </Row>
          <Carousel
            autoplay
            slidesToShow={7}
            slidesToScroll={7}
            infinite
            draggable
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {clientCarouselItems}
          </Carousel>
        </>
      }
    />
  );
};

export default DesktopScreen;
