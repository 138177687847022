import React, { useState, useEffect, useCallback } from "react";
import { Col, Image, Row, Typography } from "antd";

import LayoutDesktop from "../pages/desktop/LayoutDesktop";
import LayoutMobile from "../pages/mobile/LayoutMobile";
import grupo_fachini from "../assets/logos/grupo_fachini.png";
import "../ui/pages/QuemSomos.scss";

export default function QuemSomos() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < window.innerHeight
  );

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const content = (
    <Row align="middle" justify="center">
      <Col>
        <Typography
          className={isMobile ? "text-quem-somos-mobile" : "text-quem-somos"}
        >
          A Fachini Máquinas, integrante do grupo de empresas Fachini, foi
          fundada em 2003 e já comercializou mais de 8 mil máquinas tanto no
          território nacional quanto no exterior. Nossos equipamentos são
          amplamente empregados na América do Sul, nos Estados Unidos e na
          África, reconhecidos pela excelência e desempenho que proporcionam.
        </Typography>
        <Typography
          className={isMobile ? "text-quem-somos-mobile" : "text-quem-somos"}
        >
          Especializados no desenvolvimento, fabricação e comercialização de
          maquinário industrial, primamos pelo atendimento ao cliente, pela
          performance superior de nossos produtos e pela excelente relação
          custo-benefício. Dispomos de uma gama diversificada de máquinas
          próprias e importadas, alinhadas às exigências do mercado industrial e
          de manutenção.
        </Typography>
        <Typography
          className={isMobile ? "text-quem-somos-mobile" : "text-quem-somos"}
        >
          Presentes nos estados do Paraná e Santa Catarina, além de operações no
          Paraguai, contamos com um centro de distribuição estratégico em
          Joinville/SC, visando otimizar e agilizar as entregas, assegurando a
          plena satisfação do cliente. Nosso destaque no mercado é resultado da
          qualidade oferecida em nossos produtos próprios, bem como em peças e
          equipamentos importados.
        </Typography>
        <Image
          className={isMobile ? "img-quem-somos-mobile" : "img-quem-somos"}
          src={grupo_fachini}
          preview={false}
          style={{ width: "100%" }}
        />
      </Col>
    </Row>
  );

  return isMobile ? (
    <LayoutMobile>{content}</LayoutMobile>
  ) : (
    <LayoutDesktop content={content} />
  );
}
