import React, { useEffect, useState } from "react";

import DesktopScreen from "./pages/desktop/DesktopScreen";
import MobileScreen from "./pages/mobile/MobileScreen";
import "./App.scss";

function App() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <>{isMobile ? <MobileScreen /> : <DesktopScreen />}</>;
}

export default App;
