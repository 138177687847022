import React from "react";
import { Menu } from "antd";

const catalogoDobradeirasManuais = [
  {
    label: "Lisa",
    machineType: "dobradeiras-manuais-lisa",
  },
  {
    label: "Dentada",
    machineType: "dobradeiras-manuais-dentada",
  },
];

const catalogoDobradeirasHidraulicas = [
  {
    label: "Lisa",
    machineType: "dobradeiras-hidraulicas-lisa",
  },
  {
    label: "Dentada",
    machineType: "dobradeiras-hidraulicas-dentada",
  },
];

const catalogoTesouras = [
  { label: "Rotativa manual", machineType: "" },
  { label: "Rotativa elétrica", machineType: "" },
  { label: "Acoplada", machineType: "" },
];

const catalogoAcessorios = [
  { label: "Calandra", machineType: "calandra" },
  { label: "Fresadora", machineType: "fresadora" },
  { label: "Dobrador de suporte", machineType: "dobrador-de-suporte" },
  // { label: "Fechador de condutor", machineType: "fechador-de-condutor" },
];

const catalogoPerfiladeiras = [
  { label: "Perfiladeira simples", machineType: "perfiladeira-simples" },
  { label: "Perfiladeira dupla", machineType: "perfiladeira-dupla" },
  { label: "Perfiladeira tripla", machineType: "perfiladeira-tripla" },
];

const catalogoDesbobinadores = [{ label: "Pequeno", machineType: "" }];

const catalogoDesbobinadoresGrandes = [
  { label: "Manual", machineType: "" },
  { label: "Elétrico", machineType: "" },
];

const catalogoDobradeirasHidraulicasCNECNC = [
  { label: "CN", machineType: "dobradeiras-hidraulicas-cn" },
  // { label: "CNC", machineType: "" },
];

const catalogoGuilhotinasCNECNC = [
  { label: "CN", machineType: "guilhotinas-cn" },
  // { label: "CNC", machineType: "" },
];

const catalogoTornos = [{ label: "Convencional", machineType: "torno-convencional" }];
const catalogoFresas = [{ label: "Convencional", machineType: "" }];

const catalogoChapas = [
  { label: "Modelo aberto", machineType: "" },
  { label: "Modelo fechado", machineType: "" },
];
const catalogoCombinadoTubosEChapas = [
  { label: "Modelo aberto", machineType: "" },
  { label: "Modelo fechado", machineType: "" },
];

const HeaderMenu = ({ handleRedirect }) => (
  <Menu>
    <Menu.SubMenu key="calhas" title="Calhas">
      <Menu.SubMenu key="dobradeiras-manuais" title="Dobradeiras manuais">
        {catalogoDobradeirasManuais.map((option, index) => (
          <Menu.Item
            key={index}
          // onClick={() =>
          //   handleRedirect(
          //     "page",
          //     `/calhas/dobradeiras-manuais/${option.machineType}`
          //   )
          // }
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="dobradeiras-hidraulicas"
        title="Dobradeiras hidráulicas"
      >
        {catalogoDobradeirasHidraulicas.map((option, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              if (option.machineType.includes("dobradeiras-hidraulicas-lisa")) {
                handleRedirect(
                  "page",
                  `/calhas/dobradeiras-hidraulicas/${option.machineType}`
                );
              }
            }}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="acessorios" title="Acessorios">
        <Menu.SubMenu key="tesouras" title="Tesouras">
          {catalogoTesouras.map((option, index) => (
            <Menu.Item
              key={index}
            // onClick={() =>
            //   handleRedirect("page", `/calhas/acessorios/${option.machineType}`)
            // }
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        {catalogoAcessorios.map((option, index) => (
          <Menu.Item
            key={index}
          // onClick={() =>
          //   handleRedirect("page", `/calhas/acessorios/${option.machineType}`)
          // }
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu key="perfiladeiras" title="Perfiladeiras">
      {catalogoPerfiladeiras.map((option, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (option.machineType.includes("perfiladeira")) {
              handleRedirect("page", `/perfiladeiras/${option.machineType}`);
            }
          }}
        >
          {option.label}
        </Menu.Item>
      ))}
      <Menu.SubMenu key="desbobinadores" title="Desbobinadores">
        {catalogoDesbobinadores.map((option, index) => (
          <Menu.Item
            key={index}
          // onClick={() =>
          //   handleRedirect(
          //     "page",
          //     `/calhas/dobradeiras-manuais/${option.machineType}`
          //   )
          // }
          >
            {option.label}
          </Menu.Item>
        ))}
        <Menu.SubMenu key="grande" title="Grande">
          {catalogoDesbobinadoresGrandes.map((option, index) => (
            <Menu.Item
              key={index}
            // onClick={() =>
            //   handleRedirect(
            //     "page",
            //     `/calhas/dobradeiras-manuais/${option.machineType}`
            //   )
            // }
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu key="corte-e-dobra" title="Corte e dobra">
      <Menu.SubMenu key="dobradeiras" title="Dobradeiras">
        {catalogoDobradeirasHidraulicasCNECNC.map((option, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              if (option.machineType.includes("dobradeiras-hidraulicas-cn")) {
                handleRedirect(
                  "page",
                  `/corte-e-dobra/dobradeiras/${option.machineType}`
                );
              }
            }}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="guilhotinas" title="Guilhotinas">
        {catalogoGuilhotinasCNECNC.map((option, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              if (option.machineType.includes("guilhotinas-cn")) {
                handleRedirect(
                  "page",
                  `/corte-e-dobra/guilhotinas/${option.machineType}`
                );
              }
            }}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item>Prensa hidráulica</Menu.Item>
      <Menu.Item>Metaleira</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key="usinagem" title="Usinagem">
      <Menu.SubMenu key="tornos" title="Tornos">
        {catalogoTornos.map((option, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              if (option.machineType.includes("torno-convencional")) {
                handleRedirect(
                  "page",
                  `/usinagem/tornos/${option.machineType}`
                );
              }
            }}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="fresas" title="Fresas">
        {catalogoFresas.map((option, index) => (
          <Menu.Item
            key={index}
          // onClick={() =>
          //   handleRedirect(
          //     "page",
          //     `/calhas/dobradeiras-manuais/${option.machineType}`
          //   )
          // }
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu key="laser" title="Laser">
      <Menu.SubMenu key="corte-a-laser" title="Corte a laser">
        <Menu.Item onClick={() => handleRedirect("page", `/laser/corte-a-laser/maquina-corte-a-laser`)}>Máquina corte a laser</Menu.Item>
        <Menu.SubMenu key="chapas" title="Chapas">
          {catalogoChapas.map((option, index) => (
            <Menu.Item
              key={index}
            // onClick={() =>
            //   handleRedirect(
            //     "page",
            //     `/calhas/dobradeiras-manuais/${option.machineType}`
            //   )
            // }
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.SubMenu
          key="combinado-tubos-e-chapas"
          title="Combinado tubos e chapas"
        >
          {catalogoCombinadoTubosEChapas.map((option, index) => (
            <Menu.Item
              key={index}
            // onClick={() =>
            //   handleRedirect(
            //     "page",
            //     `/calhas/dobradeiras-manuais/${option.machineType}`
            //   )
            // }
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.Item>Tubos</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item>Solda a laser</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key="outros" title="Outros">
      <Menu.Item>Removedora de lona de freio</Menu.Item>
      <Menu.Item>Máquinas reformadas</Menu.Item>
    </Menu.SubMenu>
  </Menu>
);

export default HeaderMenu;
