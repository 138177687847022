import React from "react";

const CarouselItem = ({
  imageSrc,
  title,
  subtitle,
  onClick,
  first,
  machineType,
}) => {
  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
  };

  const itemStyle = {
    ...baseStyle,
    width: first ? "100%" : "80%",
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
  };

  const contentStyle = {
    textAlign: "center",
    backgroundColor: "#15274E",
    width: "100%",
    borderRadius: "20px 20px 0 0",
    height: "89px",
  };

  const titleStyle = {
    fontFamily: "Segoe UI",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#ffffff",
  };

  const subTitleStyle = {
    fontFamily: "Segoe UI",
    fontSize: "23px",
    fontWeight: "bold",
    color: "#ffffff",
  };

  const handleClick = () => {
    if (
      machineType !== "dobradeiras-manuais-lisa" &&
      machineType !== "dobradeiras-manuais-dentada" &&
      machineType !== "dobradeiras-hidraulicas-dentada" &&
      machineType !== "tesouras" &&
      machineType !== "calandra" &&
      machineType !== "fresadora" &&
      machineType !== "dobrador-de-suporte" &&
      machineType !== "desbobinadores" &&
      machineType !== "prensa-hidraulica" &&
      machineType !== "metaleira" &&
      machineType !== "fresa-convencional" &&
      machineType !== "solda-a-laser" &&
      machineType !== "chapas" &&
      machineType !== "combinado-tubos-e-chapas" &&
      machineType !== "tubos" &&
      machineType !== "removedora-de-lona-de-freio" &&
      machineType !== "maquinas-reformadas"
    ) {
      onClick();
    }
  };

  return (
    <div style={itemStyle} onClick={handleClick}>
      {first ? (
        <>
          <div
            style={{
              display: "flex",
              minHeight: 260,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imageSrc}
              alt={`${title} ${subtitle}`}
              style={imageStyle}
            />
          </div>
        </>
      ) : (
        <>
          <div style={contentStyle}>
            <h2 style={titleStyle}>{title}</h2>
            <h1 style={subTitleStyle}>{subtitle}</h1>
          </div>
          <div
            style={{
              display: "flex",
              minHeight: 260,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imageSrc}
              alt={`${title} ${subtitle}`}
              style={imageStyle}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CarouselItem;
