import React from "react";
import { Col, Image, Row } from "antd";
import { Link } from "react-router-dom";

import "../ui/components/MachineCarousel.scss";

const MachineCarousel = ({ image, linkTo, carousel }) => {
  const Wrapper = carousel ? Row : Col;
  const wrapperProps = carousel
    ? {
        className: "ant-row-content-machine-carousel",
        justify: "space-evenly",
        align: "middle",
      }
    : { span: 24 };

  return (
    <Wrapper {...wrapperProps}>
      <Col span={carousel ? 12 : 24}>
        <Link to={linkTo}>
          <div className="machine-carousel-header"></div>
          <div className="machine-carousel-content">
            <Image
              className="machine-carousel-image"
              src={image}
              preview={false}
            />
          </div>
        </Link>
      </Col>
    </Wrapper>
  );
};

export default MachineCarousel;
