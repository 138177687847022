import dobradeiras from "../assets/corte-e-dobra/dobradeiras-cn-e-cnc.png";
import dobradeirasCN from "../assets/corte-e-dobra/dobradeiras-hidraulicas-cn.png";
import guilhotinas from "../assets/corte-e-dobra/guilhotina.png";
import guilhotinasCN from "../assets/corte-e-dobra/guilhotinas-cn.png";
import prensaHidraulica from "../assets/corte-e-dobra/prensa-hidraulica.png";
import metaleira from "../assets/corte-e-dobra/metaleira.png";

import dobradeirasManuais from "../assets/calhas/dobradeiras-manuais.png";
import dobradeirasHidraulicas from "../assets/calhas/dobradeiras-hidraulicas.png";
import dobradeirasManuaisLisa from "../assets/calhas/dobradeiras-manuais-lisa.png";
import dobradeirasManuaisDentada from "../assets/calhas/dobradeiras-manuais-dentada.png";
import dobradeirasHidraulicasLisa from "../assets/calhas/dobradeiras-hidraulicas-lisa.png";
import dobradeirasHidraulicasDentada from "../assets/calhas/dobradeiras-hidraulicas-dentada.png";
import acessorios from "../assets/calhas/acessorios.png";
import calandra from "../assets/calhas/calandra.png";
import dobradorDeSuporte from "../assets/calhas/dobrador-de-suporte.png";
import fresadora from "../assets/calhas/fresadora.png";
import tesouras from "../assets/calhas/tesouras.png";

import perfiladeiraSimples from "../assets/perfiladeiras/perfiladeira-simples.png";
import perfiladeiraDupla from "../assets/perfiladeiras/perfiladeira-dupla.png";
import perfiladeiraTripla from "../assets/perfiladeiras/perfiladeira-tripla.png";
import desbobinadores from "../assets/perfiladeiras/desbobinadores.png";

import tornos from "../assets/usinagem/tornos.png";
import tornoConvencional from "../assets/usinagem/torno-convencional.png";
import fresas from "../assets/usinagem/fresas.png";
import fresaConvencional from "../assets/usinagem/fresa-convencional.png";

import corteALaser from "../assets/laser/corte-a-laser.png";
import soldaALaser from "../assets/laser/solda-a-laser.png";
import chapas from "../assets/laser/chapas.png";
import combinadoTubosEChapas from "../assets/laser/combinado-tubos-e-chapas.png";
import maquinaCorteALaser from "../assets/laser/maquina-corte-a-laser.png"
import tubos from "../assets/laser/tubos.png";

import removedoraDeLonaDeFreio from "../assets/outros/removedora-de-lona-de-freio.png";
import maquinasReformadas from "../assets/outros/maquinas-reformadas.png";

const calhas = [
  {
    key: "dobradeiras-manuais",
    imageSrc: dobradeirasManuais,
    url: "/calhas/dobradeiras-manuais",
    type: "dobradeiras-manuais",
  },
  {
    key: "dobradeiras-hidraulicas",
    imageSrc: dobradeirasHidraulicas,
    url: "/calhas/dobradeiras-hidraulicas",
    type: "dobradeiras-hidraulicas",
  },
  {
    key: "acessorios",
    imageSrc: acessorios,
    url: "/calhas/acessorios",
    type: "acessorios",
  },
];

const dobradeirasManuaisItems = [
  {
    key: "dobradeiras-manuais-lisa",
    imageSrc: dobradeirasManuaisLisa,
    url: "/calhas/dobradeiras-manuais/dobradeiras-manuais-lisa",
    type: "dobradeiras-manuais-lisa",
  },
  {
    key: "dobradeiras-manuais-dentada",
    imageSrc: dobradeirasManuaisDentada,
    url: "/calhas/dobradeiras-manuais/dobradeiras-manuais-dentada",
    type: "dobradeiras-manuais-dentada",
  },
];

const dobradeirasHidraulicasItems = [
  {
    key: "dobradeiras-hidraulicas-lisa",
    imageSrc: dobradeirasHidraulicasLisa,
    url: "/calhas/dobradeiras-hidraulicas/dobradeiras-hidraulicas-lisa",
    type: "dobradeiras-hidraulicas-lisa",
  },
  {
    key: "dobradeiras-hidraulicas-dentada",
    imageSrc: dobradeirasHidraulicasDentada,
    url: "/calhas/dobradeiras-hidraulicas/dobradeiras-hidraulicas-dentada",
    type: "dobradeiras-hidraulicas-dentada",
  },
];

const acessoriosItems = [
  { key: "tesouras", imageSrc: tesouras, url: "/tesouras", type: "tesouras" },
  { key: "calandra", imageSrc: calandra, url: "/calandra", type: "calandra" },
  {
    key: "fresadora",
    imageSrc: fresadora,
    url: "/fresadora",
    type: "fresadora",
  },
  {
    key: "dobrador-de-suporte",
    imageSrc: dobradorDeSuporte,
    url: "/dobrador-de-suporte",
    type: "dobrador-de-suporte",
  },
];

const perfiladeirasItems = [
  {
    key: "perfiladeira-simples",
    imageSrc: perfiladeiraSimples,
    url: "/perfiladeiras/perfiladeira-simples",
    type: "perfiladeira-simples",
  },
  {
    key: "perfiladeira-dupla",
    imageSrc: perfiladeiraDupla,
    url: "/perfiladeiras/perfiladeira-dupla",
    type: "perfiladeira-dupla",
  },
  {
    key: "perfiladeira-tripla",
    imageSrc: perfiladeiraTripla,
    url: "/perfiladeiras/perfiladeira-tripla",
    type: "perfiladeira-tripla",
  },
  {
    key: "desbobinadores",
    imageSrc: desbobinadores,
    url: "/perfiladeiras/desbobinadores",
    type: "desbobinadores",
  },
];

const corteEDobraItems = [
  {
    key: "dobradeiras",
    imageSrc: dobradeiras,
    url: "/corte-e-dobra/dobradeiras",
    type: "dobradeiras",
  },
  {
    key: "guilhotinas",
    imageSrc: guilhotinas,
    url: "/corte-e-dobra/guilhotinas",
    type: "guilhotinas",
  },
  {
    key: "prensa-hidraulica",
    imageSrc: prensaHidraulica,
    url: "/corte-e-dobra/prensa-hidraulica",
    type: "prensa-hidraulica",
  },
  {
    key: "metaleira",
    imageSrc: metaleira,
    url: "/corte-e-dobra/metaleira",
    type: "metaleira",
  },
];

const dobradeirasCNECNCItems = [
  {
    key: "dobradeiras-cn",
    imageSrc: dobradeirasCN,
    url: "/corte-e-dobra/dobradeiras/dobradeiras-hidraulicas-cn",
    type: "dobradeiras-hidraulicas-cn",
  },
];

const guilhotinasCNECNCItems = [
  {
    key: "guilhotinas-cn",
    imageSrc: guilhotinasCN,
    url: "/corte-e-dobra/guilhotinas/guilhotinas-cn",
    type: "guilhotinas-cn",
  },
];

const usinagem = [
  { key: "tornos", imageSrc: tornos, url: "/usinagem/tornos", type: "tornos" },
  { key: "fresas", imageSrc: fresas, url: "/usinagem/fresas", type: "fresas" },
];

const tornosItems = [
  {
    key: "torno-convencional",
    imageSrc: tornoConvencional,
    url: "/usinagem/tornos/torno-convencional",
    type: "torno-convencional",
  },
];
const fresasItems = [
  {
    key: "fresa-convencional",
    imageSrc: fresaConvencional,
    url: "/usinagem/fresas/fresa-convencional",
    type: "fresa-convencional",
  },
];

const laserItems = [
  {
    key: "corte-a-laser",
    imageSrc: corteALaser,
    url: "/laser/corte-a-laser",
    type: "corte-a-laser",
  },
  {
    key: "solda-a-laser",
    imageSrc: soldaALaser,
    url: "/laser/solda-a-laser",
    type: "solda-a-laser",
  },
];

const corteALaserItems = [
  {
    key: "maquina-corte-a-laser",
    imageSrc: maquinaCorteALaser,
    url: "/laser/corte-a-laser/maquina-corte-a-laser",
    type: "maquina-corte-a-laser"
  },
  {
    key: "chapas",
    imageSrc: chapas,
    url: "/laser/corte-a-laser/chapas",
    type: "chapas",
  },
  {
    key: "combinado-tubos-e-chapas",
    imageSrc: combinadoTubosEChapas,
    url: "/laser/corte-a-laser/combinado-tubos-e-chapas",
    type: "combinado-tubos-e-chapas",
  },
  {
    key: "tubos",
    imageSrc: tubos,
    url: "/laser/corte-a-laser/tubos",
    type: "tubos",
  },
];

const outros = [
  {
    key: "removedora-de-lona-de-freio",
    imageSrc: removedoraDeLonaDeFreio,
    url: "/outros/removedora-de-lona-de-freio",
    type: "removedora-de-lona-de-freio",
  },
  {
    key: "maquinas-reformadas",
    imageSrc: maquinasReformadas,
    url: "/outros/maquinas-reformadas",
    type: "maquinas-reformadas",
  },
];

const imageCollections = {
  calhas,
  dobradeirasManuaisItems,
  dobradeirasHidraulicasItems,
  acessoriosItems,
  corteEDobraItems,
  dobradeirasCNECNCItems,
  guilhotinasCNECNCItems,
  perfiladeirasItems,
  usinagem,
  tornosItems,
  fresasItems,
  laserItems,
  corteALaserItems,
  outros,
};

export default imageCollections;
