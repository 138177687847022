import React from "react";
import { Image } from "antd";

function LinkedFooterImage({ imgSrc, link, machineType }) {
  const bottomPositions = {
    maquinaconvencional: "35%",
    maquinausinagem: "39%",
    maquinalaser: "30%",
    perfiladeirasimples: "5.8%",
    perfiladeiradupla: "5.8%",
    perfiladeiratripla: "5.8%",
    dobradeirashidraulicaslisa: "5.8%",
    dobradeirashidraulicascn: "5.5%",
    guilhotinascn: "0.5%",
    tornoconvencional: "5.8%",
    maquinacortealaser: "1%"
  };

  const bottomPosition = bottomPositions[machineType.replace(/-/g, "")] || "0%";
  const linkHeight = machineType === "guilhotinas-cn" ? "7%" : "10%";
  const linkWidth = machineType === "guilhotinas-cn" ? "90%" : "70%";

  const linkStyle = {
    position: "absolute",
    bottom: bottomPosition,
    left: "50%",
    transform: "translateX(-50%)",
    width: linkWidth,
    height: linkHeight,
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Image src={imgSrc} preview={false} style={{ width: "95vw" }} />
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
        aria-label="Link para mais informações sobre a máquina"
      />
    </div>
  );
}

export default LinkedFooterImage;
