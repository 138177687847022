import { Button } from "antd";

function ButtonWhiteMenu(props) {
  return (
    <Button
      onClick={props.onClick}
      style={{
        background: "#FFFFFF",
        border: "1px solid #D9D9D9",
        textTransform: "capitalize",
        width: "100%",
        marginTop: "10px",
        textAlign: "center",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "13px",
        fontSize: "12px",
        display: "inline-block",
        padding: "0 10px",
        minHeight: "32px"
      }}
    >
      {props.text}
    </Button>
  );
}

export default ButtonWhiteMenu;
