import React from "react";
import { Button } from "antd";
import {
  InstagramOutlined,
  FacebookFilled,
  WhatsAppOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const buttonSocialMediaStyle = {
  borderRadius: "100%",
  backgroundColor: "#E01E26",
  color: "white",
};

const SocialMediaButtons = ({ handleRedirect }) => (
  <>
    <Button
      style={buttonSocialMediaStyle}
      icon={<InstagramOutlined />}
      onClick={() =>
        handleRedirect("link", "https://www.instagram.com/fachinimaquinas")
      }
    />
    <Button
      style={buttonSocialMediaStyle}
      icon={<FacebookFilled />}
      onClick={() =>
        handleRedirect("link", "https://www.facebook.com/fachiniautomotiva")
      }
    />
    <Button
      style={buttonSocialMediaStyle}
      icon={<WhatsAppOutlined />}
      onClick={() =>
        handleRedirect(
          "link",
          "https://api.whatsapp.com/send/?phone=%2B554195440770&text&type=phone_number&app_absent=0"
        )
      }
    />
    <Button
      style={buttonSocialMediaStyle}
      icon={<YoutubeOutlined />}
      onClick={() =>
        handleRedirect(
          "link",
          "https://www.youtube.com/@dobradeirasfachini2481"
        )
      }
    />
  </>
);

export default SocialMediaButtons;
