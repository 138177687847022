import { useMemo } from "react";

import dobradeirasManuais from "../../../assets/calhas/dobradeiras-manuais.png";
import dobradeirasManuaisLisa from "../../../assets/calhas/dobradeiras-manuais-lisa.png";
import dobradeirasManuaisDentada from "../../../assets/calhas/dobradeiras-manuais-dentada.png";
import dobradeirasHidraulicas from "../../../assets/calhas/dobradeiras-hidraulicas.png";
import dobradeirasHidraulicasLisa from "../../../assets/calhas/dobradeiras-hidraulicas-lisa.png";
import dobradeirasHidraulicasDentada from "../../../assets/calhas/dobradeiras-hidraulicas-dentada.png";
import acessorios from "../../../assets/calhas/acessorios.png";
import tesouras from "../../../assets/calhas/tesouras.png";
import calandra from "../../../assets/calhas/calandra.png";
import fresadora from "../../../assets/calhas/fresadora.png";
import dobradorDeSuporte from "../../../assets/calhas/dobrador-de-suporte.png";

import perfiladeiraSimples from "../../../assets/perfiladeiras/perfiladeira-simples.png";
import perfiladeiraDupla from "../../../assets/perfiladeiras/perfiladeira-dupla.png";
import perfiladeiraTripla from "../../../assets/perfiladeiras/perfiladeira-tripla.png";
import desbobinadores from "../../../assets/perfiladeiras/desbobinadores.png";

import dobradeiras from "../../../assets/corte-e-dobra/dobradeiras-cn-e-cnc.png";
import dobradeirasHidraulicasCN from "../../../assets/corte-e-dobra/dobradeiras-hidraulicas-cn.png";
import guilhotinas from "../../../assets/corte-e-dobra/guilhotina.png";
import guilhotinasCN from "../../../assets/corte-e-dobra/guilhotinas-cn.png";
import prensaHidraulica from "../../../assets/corte-e-dobra/prensa-hidraulica.png";
import metaleira from "../../../assets/corte-e-dobra/metaleira.png";

import tornos from "../../../assets/usinagem/tornos.png";
import tornoConvencional from "../../../assets/usinagem/torno-convencional.png";
import fresas from "../../../assets/usinagem/fresas.png";
import fresaConvencional from "../../../assets/usinagem/fresa-convencional.png";

import corteALaser from "../../../assets/laser/corte-a-laser.png";
import chapas from "../../../assets/laser/chapas.png";
import combinadoTubosEChapas from "../../../assets/laser/combinado-tubos-e-chapas.png";
import maquinaCorteALaser from "../../../assets/laser/maquina-corte-a-laser.png"
import tubos from "../../../assets/laser/tubos.png";
import soldaALaser from "../../../assets/laser/solda-a-laser.png";

import removedoraDeLonaDeFreio from "../../../assets/outros/removedora-de-lona-de-freio.png";
import maquinasReformadas from "../../../assets/outros/maquinas-reformadas.png";

const useMachineCarousels = () => {
  const carouselCalhas = useMemo(
    () => [
      { type: "dobradeiras-manuais", image: dobradeirasManuais },
      { type: "dobradeiras-hidraulicas", image: dobradeirasHidraulicas },
      { type: "acessorios", image: acessorios },
    ],
    []
  );

  const carouselDobradeirasManuais = useMemo(
    () => [
      { type: "dobradeiras-manuais-lisa", image: dobradeirasManuaisLisa },
      { type: "dobradeiras-manuais-dentada", image: dobradeirasManuaisDentada },
    ],
    []
  );

  const carouselDobradeirasHidraulicas = useMemo(
    () => [
      {
        type: "dobradeiras-hidraulicas-lisa",
        image: dobradeirasHidraulicasLisa,
      },
      {
        type: "dobradeiras-hidraulicas-dentada",
        image: dobradeirasHidraulicasDentada,
      },
    ],
    []
  );

  const carouselAcessorios = useMemo(
    () => [
      { type: "tesouras", image: tesouras },
      { type: "calandra", image: calandra },
      { type: "fresadora", image: fresadora },
      { type: "dobrador-de-suporte", image: dobradorDeSuporte },
    ],
    []
  );

  const carouselPerfiladeiras = useMemo(
    () => [
      { type: "perfiladeira-simples", image: perfiladeiraSimples },
      { type: "perfiladeira-dupla", image: perfiladeiraDupla },
      { type: "perfiladeira-tripla", image: perfiladeiraTripla },
      { type: "desbobinadores", image: desbobinadores },
    ],
    []
  );

  const carouselCorteEDobra = useMemo(
    () => [
      { type: "dobradeiras", image: dobradeiras },
      { type: "guilhotinas", image: guilhotinas },
      { type: "prensa-hidraulica", image: prensaHidraulica },
      { type: "metaleira", image: metaleira },
    ],
    []
  );

  const carouselDobradeirasHidraulicasCNECNC = useMemo(() => [{ type: "dobradeiras-hidraulicas-cn", image: dobradeirasHidraulicasCN }], []);

  const carouselGuilhotinasCNECNC = useMemo(
    () => [{ type: "guilhotinas-cn", image: guilhotinasCN }],
    []
  );

  const carouselUsinagem = useMemo(
    () => [
      { type: "tornos", image: tornos },
      { type: "fresas", image: fresas },
    ],
    []
  );

  const carouselTornos = useMemo(
    () => [{ type: "torno-convencional", image: tornoConvencional }],
    []
  );

  const carouselFresas = useMemo(
    () => [{ type: "fresa-convencional", image: fresaConvencional }],
    []
  );

  const carouselLaser = useMemo(() => [
    { type: "corte-a-laser", image: corteALaser },
    { type: "solda-a-laser", image: soldaALaser },
  ], []);

  const carouselCorteALaser = useMemo(() => [
    { type: "maquina-corte-a-laser", image: maquinaCorteALaser },
    { type: "chapas", image: chapas },
    { type: "combinado-tubos-e-chapas", image: combinadoTubosEChapas },
    { type: "tubos", image: tubos },
  ], []);

  const carouselOutros = useMemo(() => [
    { type: "removedora-de-lona-de-freio", image: removedoraDeLonaDeFreio },
    { type: "maquinas-reformadas", image: maquinasReformadas },
  ], []);

  return {
    carouselCalhas,
    carouselDobradeirasManuais,
    carouselDobradeirasHidraulicas,
    carouselAcessorios,
    carouselCorteEDobra,
    carouselDobradeirasHidraulicasCNECNC,
    carouselGuilhotinasCNECNC,
    carouselPerfiladeiras,
    carouselUsinagem,
    carouselTornos,
    carouselFresas,
    carouselLaser,
    carouselCorteALaser,
    carouselOutros,
  };
};

export default useMachineCarousels;
